.customInput{
    width: 100%;
    border: 0px;
    outline: 0;
    padding: 4px 4px 12px 4px;
}

.selectInput{
    width: 80%;
    border: 0px;
    outline: 0;
    padding: 4px 4px 12px 4px;
}