.loader {
  position: absolute;
  margin: auto;
  border: 20px solid #eaf0f6;
  border-radius: 50%;
  border-top: 20px solid #d22b2b;
  width: 200px;
  height: 200px;
  animation: spinner 2s linear infinite;
}

.loader.sm {
  position: absolute;
  margin: auto;
  border: 15px solid #eaf0f6;
  border-radius: 50%;
  border-top: 15px solid #d22b2b;
  width: 80px;
  height: 80px;
  animation: spinner 2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
