body {
  overflow-x: scroll;
}
.customForPrint svg {
  width: 120px;
  height: 60px;
}
.page {
  width: 816px;
  height: 1056px;
  /* border: 1px solid red; */
}

.customMargin {
  margin: none;
}
