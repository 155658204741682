.stateCustomTable {
  display: table;
}
.stateCustomTable {
  position: relative;
  text-align: left;
}

.stateCustomTable :where(th, td) {
  white-space: nowrap;
  padding: 1rem; /* 16px */
  width: 75%;
  vertical-align: middle;
}
.stateCustomTable :where(thead) :where(th) {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

.stateCustomTable:where(:not(.stateCustomTable-zebra))
  :where(thead, tbody, tfoot)
  :where(tr:not(:last-child) :where(th, td)) {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
}
.stateCustomTable :where(thead) :where(th, td) {
  --tw-bg-opacity: 1;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  font-weight: 700;
  text-transform: uppercase;
}
