@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .blackBox {
    box-shadow: inset 4px 4px 6px #212121, inset -4px -4px 6px #313131;
  }
}

.dark body {
  background: #292929;
}

/* width */
/* ::-webkit-scrollbar {
    display: none;
} */

.zoom {
  transition: 0.3s ease-in-out;
}

.zoom:hover {
  transform: scale(1.06);
}

/* ckEditor custom css */
.ck.ck-editor__editable_inline {
  height: 50vh !important;
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }

  /* body {
    margin: 0;
    padding: 0;
  } */

  .printcontent {
    width: 210mm;
    height: 297mm;

    /* Other styles */
  }
}
