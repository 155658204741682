.imageUploadBox{
    cursor: pointer;
  width: 100px;
  height: 100px;
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.file{
 cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  
}
.file ::file-selector-button {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

.layerZero {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  font-size: 35px;
  color: #aaaaaa;
}
.layer {
  cursor: pointer;
  opacity: 1;
  position: absolute;
  font-size: 35px;
  color: #aaaaaa;
}