.checkbox {
  opacity: 0;
  position: absolute;
}

.label {
  cursor: pointer;
  width: 50px;
  height: 24px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.2);
}

.ball {
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

/*  target the elemenent after the label*/
.checkbox:checked + .label .ball {
  transform: translateX(24px);
}

.fa-moon {
  color: pink;
}

.fa-sun {
  color: yellow;
}
.logoutBtn {
  /* border: 1px solid rgb(223, 223, 223); */
  width: 100%;
  height: fit-content;
  text-align: left;
  padding: 0;
}
.customPadding {
  /* padding-top: 5px; */
  padding-left: 5px;
  /* margin-bottom: 5px;   */
}
.userBtn {
  /* pointer-events: none; */
  padding: 0;
}
.customWidth:first-child {
  border-bottom: none;
}
.customWidth:last-child {
  border-bottom: none;
}
.dark .customWidth:not(:first-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
}
.dark .customWidth:last-child {
  border-bottom: none;
}
.customWidth {
  height: 35px;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: start;
  /* border: 1px solid red; */
}
