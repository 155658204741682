.buttonContainer {
  width: 250px;
  font-size: 1rem;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
}

.filterButton {
  cursor: pointer;
  font-size: 1.5rem;
  border: 2px solid;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.filterIcon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.filterButton:hover {
  /* width: 125px; */
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.filterButton:active {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.05s;
}
