/* .grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 10px;
  padding: 10px;
}

.grid-container > div {
  border: 1px solid #000;
}

.item1 {
  grid-column: 1 / span 1;
}
 */

.dark .cus-container {
  background: #1b1a1a;
}
.cus-container {
  background: #f2f2f2;
}

/* START */

/* * For rendering images inserted using the image plugin. * Includes image captions using the HTML5 figure element. */

/* .dark .tox .tox-edit-area__iframe {
  background: #1b1a1a !important;
  color: #ccc !;
} */

.parser h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.parser h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.parser h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.parser h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.parser h5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.parser h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

/* .parser table {
  border: 1px solid #eee;
} */

.parser table tr:nth-child(1) {
  font-weight: 600;
}

.parser table tr:nth-child(even) {
  background: #eee;
}

.parser table td {
  border: 1px solid #ddd;
  padding: 10px 15px;
}

.parser figure.image {
  display: inline-block;
  border: 1px solid gray;
  margin: 0 2px 0 1px;
  background: #f5f2f0;
}
.parser figure.align-left {
  float: left;
}
.parser figure.align-right {
  float: right;
}
.parser figure.image img {
  margin: 8px 8px 0 8px;
}
.parser figure.image figcaption {
  margin: 6px 8px 6px 8px;
  text-align: center;
}
.parser img.align-left {
  float: left;
}
.parser img.align-right {
  float: right;
}
.parser .mce-toc {
  border: 1px solid gray;
}
.parser .mce-toc h2 {
  margin: 4px;
}
.parser .mce-toc li {
  list-style-type: none;
}
.parser .tox-checklist > li:not(.tox-checklist--hidden) {
  list-style: none;
  margin: 0.25em 0;
  position: relative;
}
.parser .tox-checklist > li:not(.tox-checklist--hidden)::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-unchecked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2215%22%20height%3D%2215%22%20x%3D%22.5%22%20y%3D%22.5%22%20fill-rule%3D%22nonzero%22%20stroke%3D%22%234C4C4C%22%20rx%3D%222%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
  cursor: pointer;
  height: 1em;
  margin-left: -1.5em;
  margin-top: 0.125em;
  position: absolute;
  width: 1em;
}
.parser
  .tox-checklist
  li:not(.tox-checklist--hidden).tox-checklist--checked::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-checked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%234099FF%22%20fill-rule%3D%22nonzero%22%20rx%3D%222%22%2F%3E%3Cpath%20id%3D%22Path%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%20d%3D%22M11.5703186%2C3.14417309%20C11.8516238%2C2.73724603%2012.4164781%2C2.62829933%2012.83558%2C2.89774797%20C13.260121%2C3.17069355%2013.3759736%2C3.72932262%2013.0909105%2C4.14168582%20L7.7580587%2C11.8560195%20C7.43776896%2C12.3193404%206.76483983%2C12.3852142%206.35607322%2C11.9948725%20L3.02491697%2C8.8138662%20C2.66090143%2C8.46625845%202.65798871%2C7.89594698%203.01850234%2C7.54483354%20C3.373942%2C7.19866177%203.94940006%2C7.19592841%204.30829608%2C7.5386474%20L6.85276923%2C9.9684299%20L11.5703186%2C3.14417309%20Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
}
.parser .tiny-pageembed--21by9,
.parser .tiny-pageembed--16by9,
.parser .tiny-pageembed--4by3,
.parser .tiny-pageembed--1by1 {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.parser .tiny-pageembed--21by9 {
  padding-top: 42.857143%;
}
.parser .tiny-pageembed--16by9 {
  padding-top: 56.25%;
}
.parser .tiny-pageembed--4by3 {
  padding-top: 75%;
}
.parser .tiny-pageembed--1by1 {
  padding-top: 100%;
}
.parser .tiny-pageembed--21by9 iframe,
.parser .tiny-pageembed--16by9 iframe,
.parser .tiny-pageembed--4by3 iframe,
.parser .tiny-pageembed--1by1 iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.parser .ephox-summary-card {
  border: 1px solid #aaa;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 10px;
  overflow: hidden;
  margin-bottom: 1em;
}
.parser .ephox-summary-card a {
  text-decoration: none;
  color: inherit;
}
.parser .ephox-summary-card a:visited {
  color: inherit;
}
.parser .ephox-summary-card-title {
  font-size: 1.2em;
  display: block;
}
.parser .ephox-summary-card-author {
  color: #999;
  display: block;
  margin-top: 0.5em;
}
.parser .ephox-summary-card-website {
  color: #999;
  display: block;
  margin-top: 0.5em;
}
.parser .ephox-summary-card-thumbnail {
  max-width: 180px;
  max-height: 180px;
  margin-left: 2em;
  float: right;
}
.parser .ephox-summary-card-description {
  margin-top: 0.5em;
  display: block;
}
.parser div.mce-footnotes hr {
  margin-inline-end: auto;
  margin-inline-start: 0;
  width: 25%;
}
.parser div.mce-footnotes li > a.mce-footnotes-backlink {
  text-decoration: none;
}
.parser blockquote {
  border-left: 2px solid #ccc;
  margin-left: 1.5rem;
  padding-left: 1rem;
}
/* .parser .mce-content-body[dir="rtl"] blockquote {
  border-right: 2px solid #ccc;
  margin-right: 1.5rem;
  padding-right: 1rem;
} */

.parser code {
  background-color: #e8e8e8;
  border-radius: 3px;
  padding: 0.1rem 0.2rem;
}

@media print {
  .parser sup.mce-footnote a {
    color: black;
    text-decoration: none;
  }
  .parser div.mce-footnotes {
    break-inside: avoid;
    width: 100%;
  }
  .parser div.mce-footnotes li > a.mce-footnotes-backlink {
    display: none;
  }
}

/* END */

/* CK content */
/* .dark .ck.ck-toolbar {
  background: #292929 !important;
  color: #eee !important;
  border: 1px solid #444;
}

.dark .ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: #eee !important;
} */

/* // .dark .ck-editor__editable {
//   background: #292929 !important;
//   border: 1px solid #444 !important;
// }
// .ck.ck-content h2 {
//   font-size: 244.1%;
//   font-weight: 700;
// }
// .ck.ck-content h3 {
//   font-size: 195.3%;
//   font-weight: 700;
// }
// .ck.ck-content h4 {
//   font-size: 156.3%;
//   font-weight: 700;
// } */

/* tinyMCE */

.tox .tox-dialog.loading::after {
  content: "Uploading...";
  background: rgba(255, 255, 255, 0.75) !important;
  position: absolute !important;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0 !important;
  z-index: 500 !important;
}

.tox-statusbar__branding {
  display: none !important;
}

@media screen and (max-width: 1550px) {
  .my-container {
    padding: 0 2rem;
  }
}
